<template>
  <RuesBoxSize :size="props.item?.width">
    <SharedCard>
      <template #img>
        <DelayedComponent :delay="3000">
          <img
            loading="lazy"
            src="https://img.travanto.de/travanto/img/elegantes-fahrrad-in-der-natur.webp"
            alt="Mietrad.de"
            height="auto"
            width="auto"
            :class="[ 'aspect-[16/9] object-cover object-center w-full fade-in' ]"
          >
          <template #preview>
            <div :class="[ 'aspect-[16/9] object-cover object-center w-full' ]" />
          </template>
        </DelayedComponent>
      </template>
      <template #text>
        <div class="grid gap-3 h-full">
          <UiIcon src="/svg/logo/Mietrad_Logo_2024.svg" width="100" height="55" title="Mietrad.de Logo" class="w-full max-w-[210px] h-[55px] mx-auto" />
          <div class="text-3xl font-bold leading-8 text-center">
            {{ t('headline', { region: portal.location }) }}
          </div>
          <div class="flex w-full gap-4 mt-4 text-base self-end">
            <UiMaskedLink
              :href="props.item?.url || ''"
              :new-tab="true"
              class="w-full"
            >
              <UiButton color="primary" class="w-full">
                {{ t('button') }}
              </UiButton>
            </UiMaskedLink>
          </div>
        </div>
      </template>
    </SharedCard>
  </RuesBoxSize>
</template>

<script setup>
const t = useTranslations('RuesCardMietrad').translate
const { links: portal } = usePortalData()
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>
