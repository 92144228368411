<template>
  <RuesBoxSize :size="props.item?.width">
    <UiHeadline v-if="props.item?.headline" type="h2" design="h2" class="mb-4">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="props.item?.headline" />
      <!-- eslint-enable vue/no-v-html -->
    </UiHeadline>
    <div v-accordion class="rues-text-starter">
      <!-- eslint-disable vue/no-v-html -->
      <span class="rues-text-start" v-html="props.item?.text" />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})

// This function is necessary because the accordions are located in a language placeholder and cannot be accessed via a ref
const vAccordion = {
  mounted (el) {
    const triggers = el.querySelectorAll('.card-header')
    triggers.forEach((trigger) => {
      const arrow = document.createElement('span')
      arrow.classList.add('accordion-arrow-new')
      trigger.appendChild(arrow)
      arrow.innerHTML = ' '
      trigger.addEventListener('click', () => {
        const collapse = trigger.nextElementSibling
        const isCollapsed = collapse.style.display === 'none'
        collapse.style.display = isCollapsed ? 'block' : 'none'
        arrow.classList.toggle('expanded', isCollapsed)
      })
    })
  }
}

// This function is necessary because the tables are located in a language placeholder and cannot be accessed via a ref
function scrollTable () {
  // Find all containers with the class "rues-text-starter"
  const containers = document.querySelectorAll('.rues-text-start')
  containers.forEach((container) => {
    // Find all tables within the current container
    const tables = container.querySelectorAll('table')

    tables.forEach((table) => {
      // Check if the table is already wrapped in a div with the class "rues-text-table-scrollable"
      if (table.parentNode.className !== 'rues-text-table-scrollable') {
        // Create a new div with the class "rues-text-table-scrollable"
        const div = document.createElement('div')
        div.classList.add('rues-text-table-scrollable')

        // Replace the table with the new div and append the table to the div
        table.parentNode.replaceChild(div, table)
        div.appendChild(table)
      }
    })
  })
}
onMounted(() => {
  const element = document.querySelector('.rues-text-starter')
  if (element) {
    vAccordion.mounted(element)
  }
  scrollTable()
})
</script>

<style lang="scss" scoped>
@import "./scss/RuesText.scss";
:deep(.rues-text-starter){
  @apply text-left;

  .rues-text-start :first-child{
    @apply mt-0;
  }
  h1{
    @apply text-3xl md:text-5xl font-bold mt-12 mb-12 leading-[2.1rem] md:leading-[2.7rem];
    +h1, + h2, + h3, + h4, + h5, + h6{
      @apply mt-0;
    }
  }
  h2{
    @apply text-2xl md:text-4xl font-semibold mt-12 mb-4 leading-[1.8rem] md:leading-[2.25rem];
    +h1, + h2, + h3, + h4, + h5, + h6{
      @apply mt-0;
    }
  }
  h3{
    @apply text-xl md:text-2xl font-semibold mt-12 mb-4 leading-[1.5rem] md:leading-[1.75rem];
    +h1, + h2, + h3, + h4, + h5, + h6{
      @apply mt-0;
    }
  }
  h4{
    @apply text-lg md:text-lg font-semibold mt-12 mb-4 leading-[1.35rem];
    +h1, + h2, + h3, + h4, + h5, + h6{
      @apply mt-0;
    }
  }
  h5{
    @apply text-base md:text-base font-semibold mt-12 mb-4;
    +h1, + h2, + h3, + h4, + h5, + h6{
      @apply mt-0;
    }
  }
  ul{
    @apply list-disc ml-5 mb-2;
  }
  ol{
    @apply list-decimal ml-5 mb-2;
  }
  a{
    @apply text-petrol-500 underline hover:text-petrol-800;
  }
  img{
    @apply aspect-[16/9] object-cover object-center w-full;
  }

  .rues-mb-5 {
    @apply border border-neutral-300 rounded-lg overflow-hidden;
  }

  .rues-mb-3 {
    @apply p-5 border-b border-neutral-300 mb-0;
  }

  .border-bottom {
    @apply px-5;
  }

  .rues-tagbox-dropdown-js {
    @apply mt-5;
  }

  .accordion {
    @apply border-t mt-2 border-neutral-300;

    .card {
      @apply border-0;

      .card-header {
        @apply bg-white px-5 pt-4 cursor-pointer flex relative text-center;
        justify-content: space-between;
      }

      .collapse {
        visibility: visible;
        @apply border-t mt-4 border-neutral-300;
      }

      .border-top {
        @apply py-2 px-5;
      }

      .rounded-b-lg {
        @apply rounded-xl;
      }

      .btn {
        @apply text-left;

        &-white {
          @apply bg-white;
        }

        &-block {
          @apply w-full;
        }

        &.rounded-0 {
          @apply rounded-none;
        }
      }
    }
  }

  .accordion-arrow-new::before {
    content: '';
    @apply inline-block h-[10px] w-[10px] border-r-2 border-b-2 border-r border-b rotate-45
  }

  .accordion-arrow-new.expanded::before {
    @apply rotate-[-135deg]
  }
  .rues-text-table-scrollable {
    @apply pb-3 mt-5 mb-5 w-full overflow-y-auto;
  }
  .rues-text-table-scrollable::-webkit-scrollbar {
    @apply pt-4 h-[0.8rem] w-[0.8rem];
    -webkit-appearance: none;
  }

  .rues-text-table-scrollable::-webkit-scrollbar-thumb {
    @apply rounded-[1.3rem] bg-neutral-300;
  }
  table{
    @apply border-separate border-spacing-0;
    th, td{
      @apply p-4 border-b border-r border-neutral-300;
    }
    th{
      @apply bg-neutral-50 border-t;
      &:first-child{
        @apply rounded-tl-xl border-l;
      }
      &:last-child{
        @apply rounded-tr-xl;
      }
    }
    tr{
      &:last-child{
        td{
          &:first-child {
            @apply rounded-bl-xl;
          }
          &:last-child {
            @apply rounded-br-xl;
          }
        }
      }
      td {
        &:first-child {
          @apply border-l;
        }
      }
    }
  }
  .contentsite-imgbox-wrap {
    @apply grid grid-cols-12 gap-x-4 gap-y-8 mb-5 mt-12 text-left relative;
    .contentsite-imgbox-in-small {
      @apply col-span-12 md:col-span-6 lg:col-span-4;
    }
    .contentsite-imgbox-in-big {
      @apply col-span-12 md:col-span-6 lg:col-span-6 text-lg;
    }
  }
  #inhalt {
    aspect-ratio: auto !important;
    @apply rounded-xl;
  }
}
</style>
